import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import theme from 'styles/theme'

export const Links = styled.div`
  display: inline-flex;
  margin: 1rem 0;
`

const linkStyles = css`
  width: 2.4rem;
  height: 2.4rem;
  color: white;
  margin-right: 1rem;

  path {
    fill: ${theme.color.muted};
    transition: fill 0.2s ease-in-out;
  }

  svg {
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    text-decoration: none;

    svg {
      transform: translateY(-0.3rem);
    }

    path {
      fill: ${theme.color.accent.blue};
    }
  }
`

export const ExternalLink = styled.a`
  ${linkStyles};
`
