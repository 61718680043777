import React from 'react'
import { Link } from 'gatsby'
import Layout from 'components/layout'
import { Mdx } from 'common/types'
import Avatar from 'components/avatar'
import SocialLinks from 'components/social-links'
import { Wrapper, Title, Paragraph, Grid, SubTitle } from './styled'
import ArticleCard from 'components/article-card'
import CourseCard from './course-card'

type Props = {
  data: {
    featuredPosts: {
      edges: ReadonlyArray<{
        node: Mdx
      }>
    }
  }
}

const Home = ({ data: { featuredPosts } }: Props) => (
  <Layout>
    <Wrapper>
      <Avatar />
      <div>
        <Title>Hi, I'm Konstantin!</Title>
        <Paragraph>
          I'm a web developer, <Link to="speaking">public speaker</Link>, and
          online educator. Check out a list of my{' '}
          <Link to="courses">video courses</Link> or read the latest musings
          down below.
        </Paragraph>
        <SocialLinks />
      </div>
    </Wrapper>
    <SubTitle>Featured courses</SubTitle>
    <Grid>
      <CourseCard
        title="Animations with Framer-motion"
        url="https://www.youtube.com/playlist?list=PLNG2YBDrzK-yhlQtfsrzzQvaLDVj-pMXI"
        imageUrl="/photos/courses/framer-motion.png"
      />
      <CourseCard
        title="State management with Rematch"
        url="https://www.youtube.com/playlist?list=PLNG2YBDrzK-w1VSeDpMxdGwkb4L6hDy8Z"
        imageUrl="/photos/courses/rematch.png"
      />
      <CourseCard
        title="Framer X tips"
        url="https://www.youtube.com/playlist?list=PLNG2YBDrzK-wiwBelivGBYeuLtfi1uySW"
        imageUrl="/photos/courses/framer-x.png"
      />
    </Grid>
    <SubTitle>
      Featured posts
      <Link to="blog">View all</Link>
    </SubTitle>
    <Grid>
      {featuredPosts.edges.map(edge => (
        <ArticleCard key={edge.node.fields.slug} item={edge.node} />
      ))}
    </Grid>
  </Layout>
)

export default Home
