import styled from 'styled-components'
import { hoverEffect } from 'styles/mixins'
import mq from 'styles/mq'
import theme from 'styles/theme'

export const Wrapper = styled.div`
  padding: 5rem 0;
  text-align: center;

  ${mq.medium} {
    display: flex;
    padding: 2rem 0 5rem;
    text-align: left;
  }
`

export const Title = styled.h1`
  font-size: 3rem;
  margin: 0;

  ${mq.large} {
    font-size: 4.5rem;
  }
`

export const Paragraph = styled.p`
  a {
    ${hoverEffect};
  }
`

export const Grid = styled.div`
  display: grid;
  gap: 1rem;

  ${mq.medium} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const SubTitle = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    font-weight: 100;
    font-size: 1.6rem;
    color: ${theme.color.textDark};

    &:hover {
      color: ${theme.color.accent.blue};
    }
  }
`
