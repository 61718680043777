import React from 'react'
import GitHubIcon from 'components/icons/github'
import YouTubeIcon from 'components/icons/youtube'
import MediumIcon from 'components/icons/medium'
import TwitterIcon from 'components/icons/twitter'
import LinkedinIcon from 'components/icons/linkedin'
import DevToIcon from 'components/icons/devto'
import DribbbleIcon from 'components/icons/dribbble'
import CodeSandboxIcon from 'components/icons/codesandbox'
import { Links, ExternalLink } from './styles'

const SocialLinks = () => (
  <Links>
    <ExternalLink
      href="https://medium.com/@koss_lebedev"
      target="_blank"
      rel="noreferrer"
    >
      <MediumIcon />
    </ExternalLink>
    <ExternalLink
      href="https://github.com/koss-lebedev"
      target="_blank"
      rel="noreferrer"
    >
      <GitHubIcon />
    </ExternalLink>
    <ExternalLink
      href="https://www.youtube.com/channel/UCtPMY3omsVohjQOTCsWbi4g"
      target="_blank"
      rel="noreferrer"
    >
      <YouTubeIcon />
    </ExternalLink>
    <ExternalLink
      href="https://twitter.com/koss_lebedev"
      target="_blank"
      rel="noreferrer"
    >
      <TwitterIcon />
    </ExternalLink>
    <ExternalLink
      href="https://www.linkedin.com/in/konstantin-lebedev/"
      target="_blank"
      rel="noreferrer"
    >
      <LinkedinIcon />
    </ExternalLink>
    <ExternalLink
      href="https://codesandbox.io/u/koss-lebedev"
      target="_blank"
      rel="noreferrer"
    >
      <CodeSandboxIcon />
    </ExternalLink>
    <ExternalLink
      href="https://dev.to/kosslebedev/"
      target="_blank"
      rel="noreferrer"
    >
      <DevToIcon />
    </ExternalLink>
    <ExternalLink
      href="https://dribbble.com/koss_lebedev/"
      target="_blank"
      rel="noreferrer"
    >
      <DribbbleIcon />
    </ExternalLink>
  </Links>
)

export default SocialLinks
