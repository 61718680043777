import React from 'react'
import { Preview, Wrapper, Title } from './styled'

type Props = {
  title: string
  url: string
  imageUrl: string
}

const CourseCard = ({ title, imageUrl, url }: Props) => {
  return (
    <Wrapper href={url} target="_blank" rel="noreferrer">
      <Preview src={imageUrl} />
      <Title>{title}</Title>
    </Wrapper>
  )
}

export default CourseCard
