import styled from 'styled-components'
import mq from 'styles/mq'

export const Wrapper = styled.div`
  margin: 0 auto 3rem;
  max-width: 12rem;

  img {
    border-radius: 50%;
  }

  ${mq.medium} {
    padding-right: 4rem;
    max-width: 16rem;
    margin: 0;
  }
`
