import styled from 'styled-components'
import mq from 'styles/mq'
import theme from 'styles/theme'

export const Title = styled.p`
  font-weight: bold;
  margin: 0.5rem 0;
`

export const Wrapper = styled.a`
  color: ${theme.color.textDark};

  &:hover {
    ${Title} {
      color: ${theme.color.accent.blue};
    }
  }
`

export const Preview = styled.img`
  height: 20rem;
  width: 100%;
  object-fit: cover;

  ${mq.medium} {
    height: 16rem;
  }
`
