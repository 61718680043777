import { graphql } from 'gatsby'
import Home from 'modules/home'

export default Home

export const pageQuery = graphql`
  query {
    featuredPosts: allMdx(
      filter: { frontmatter: { featured: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM YYYY")
            title
            description
            category
            featured
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
          }
        }
      }
    }
  }
`
