import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Wrapper } from './styled'

const Avatar = () => {
  return (
    <Wrapper>
      <StaticImage src="./profile-pic.jpg" alt="Photo of Konstantin" />
    </Wrapper>
  )
}

export default Avatar
